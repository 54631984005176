import request from '@/libs/axios'


export function getDocumentYears(params){
  return request({
    url: '/api/documents/get-sort-by-year',
    method: 'get',
    params,
  });
}
