<template>
  <div>
    <h2 class="text-center">
      {{ category.name }}
    </h2>
    <b-row class="mt-3">
      <b-col
        v-for="(year, index) in years"
        :key="index"
        :md="4"
      >
        <b-card
          style="cursor: pointer"
          @click="goToDocumentsByYear(year.id)"
          :bg-variant="index === years.length - 1 ? 'success' : ''"
        >
          <h4 class="text-center" :class="index === years.length - 1 ? 'text-white' : ''">
            {{ year.year }}
          </h4>
          <p v-if="index === 0" class="text-center" :class="index === years.length - 1 ? 'text-white' : ''">({{ year.documents_count }})
            <span v-if="index > 0" :class="index === years.length - 1 ? 'text-white' : ''">{{ getDocDiference(index) }}</span>
          </p>
          <p v-else class="text-center" :class="index === years.length - 1 ? 'text-white' : ''">
            ({{ getDocumentsCount(index) }})
            <span v-if="index > 0" :class="index === years.length - 1 ? 'text-white' : ''">+{{ year.documents_count }}</span>
          </p>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getDocumentYears } from '@/api/all'

export default {
  data() {
    return {
      years: [],
      category: null,
    }
  },
  mounted() {
    console.log(this.$route.params.id)
  },
  created() {
    this.fetchDocumentYears()
  },
  methods: {
    fetchDocumentYears() {
      getDocumentYears({ category_id: this.$route.params.id }).then(res => {
        this.years = res.data.data
        this.category = res.data.category
      })
    },
    goToDocumentsByYear(year) {
      this.$router.push({
        name: 'DocumentsByCategoryAndYear',
        params: {
          yearId: year, catId: this.$route.params.id,
        },
      })
    },
    getDocDiference(index) {
      const diff = this.years[index].documents_count - this.years[index - 1].documents_count
      if (diff > 0) {
        return `+ ${diff}`
      }
      return `- ${diff}`
    },
    getDocumentsCount(index) {
      let sum = 0;
      for (let i = 0; i <= index; i++) {
        sum += this.years[i].documents_count;
      }
      return sum;
    }
  },
}
</script>

<style scoped>
h2{
  /*margin-left: 600px;*/
  font-weight: bold;
}
</style>
